import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/Container';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import { ethers } from 'ethers';

import TextareaAutosize from '@mui/material/TextareaAutosize';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import ReactGA from 'react-ga';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function InfoPageModal({ linkText }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [isShowingAlert, setShowingAlert] = React.useState(false);

  function Content() {
    ReactGA.pageview(window.location.pathname + window.location.search)
    return (

        <TableContainer maxWidth="sm">
        <p className="txdetail-title">About txpool.info</p>
 
        <p>
            txpool.info provide real-time ethereum mempool/txpool infomration through webpage and json files.
            </p>
            <p>
            Please check following URLS
        </p>
        <nav aria-label="secondary mailbox folders">
        <List>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText primary="webpage: txpool.info" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton component="a" href="#simple-list">
              <ListItemText primary="webpage: txpool.info/tx/0xXXXXX" />
            </ListItemButton>
          </ListItem>
        </List>
        <List>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText primary="json: txpool.info/pool.json (tx age: 10 tx)" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton component="a" href="#simple-list">
              <ListItemText primary="json: txpool.info/pool-1m-old.json (tx age: 60 tx)" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton component="a" href="#simple-list">
              <ListItemText primary="json: txpool.info/pool-10m-old.json (tx age: 600 tx)" />
            </ListItemButton>
          </ListItem>
        </List>
      </nav>
      </TableContainer>

    );
  }
  return (
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            className={`alert alert-success ${isShowingAlert ? 'alert-shown' : 'alert-hidden'}`}
            onTransitionEnd={() => setShowingAlert(false)}
            align="center"
            color="red"
          >
            <strong><font color="darkred">Copied!</font></strong>
          </div>
          <Content />
        </Box>
      </Modal>
  );
}

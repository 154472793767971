import DataTable from 'react-data-table-component';
import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import { ethers } from 'ethers';
import Link from '@mui/material/Link';
import TxDetail from './TxDetail';
import TxDetail2 from './TxDetail2';
import InfoPage from './Info';
import ReactGA from 'react-ga';
import VolunteerActivismOutlinedIcon from '@mui/icons-material/VolunteerActivismOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';

import {
  useLocation,
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
/// apps

const columns2 = [
  { field: 'id', headerName: 'ID', width: 70 },
  {
    field: 'age', headerName: 'First Seen/Age', width: 150, sortable: true,
  },
  {
    field: 'type',
    headerName: 'Type',
    width: 60,
  },
  {
    field: 'hash',
    headerName: 'Hash',
    width: 300,
    renderCell: (params) => (
      <TxDetail hash={params.row.hash} data={params.row} />
    ),
  },

  {
    field: 'from', headerName: 'From', width: 340, sortable: true,
  },
  {
    field: 'to', headerName: 'To', width: 350, sortable: true,
  },

  {
    field: 'value',
    headerName: 'Value (ether)',
    description: 'This column has a value getter and is not sortable.',
    sortable: true,
    width: 160,
  },
  { field: 'gas_limit', headerName: 'Gas Limit (wei)', width: 120 },
  { field: 'gas_price', headerName: 'Gas Price (gwei)', width: 150 },
];

// string array

function App() {
  ReactGA.initialize('G-0NCGWVE4D3');
  ReactGA.pageview(window.location.pathname + window.location.search);

  // console.log("ethers.utils.formatUnits: " + ethers.utils.formatUnits("1000", "gwei"))
  // ethers.utils.formatEther(item.value)
  console.log("useLocation: " + JSON.stringify(useLocation()))
  return (
/*     <BrowserRouter>
    <div className="myClass">
    <Routes>
          <Route exact path='/' element={< TXList />}></Route>
          <Route exact path='/about' element={< InfoPage />}></Route>

   </Routes>
   </div>
</BrowserRouter>
 */
<div className="myClass">

<TXList />

<Routes>
          <Route exact path='/info' element={< InfoPage />}></Route>
   </Routes>

</div>
  );
}

function timeSince(date) {
  const seconds = Math.floor((new Date() - date) / 1000);

  let interval = seconds / 31536000;

  if (interval > 1) {
    return `${Math.floor(interval)} years`;
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return `${Math.floor(interval)} months`;
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return `${Math.floor(interval)} days`;
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return `${Math.floor(interval)} hours`;
  }
  interval = seconds / 60;
  if (interval > 1) {
    return `${Math.floor(interval)} minutes`;
  }
  return `${Math.floor(seconds)} seconds`;
}

class TXList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      books: [],
    };
  }

  componentDidMount() {
    /*     fetch('http://localhost:8080/txpool')
      .then(response => response.json())
      .then(bookData => this.setState({ books: bookData.books })) */

    //setInterval(() => this.loadData(), 6000);
    this.loadData();
  }

  async loadData() {
    try {
      const res = await fetch('https://api.txpool.info/txpool.json');
      //const res = await fetch('http://localhost:' + 24111 +'/txpool.json');
      //http://158.69.127.25:8080/txpool
      const blocks = await res.json();
      console.log(blocks);

      this.setState({
        books: blocks,
      });
    } catch (e) {
      console.log(e);
    }
  }

  // hex to

  buildTable = () => {
    // const [pageSize, setPageSize] = React.useState(100);
    const data = this.state.books.map((item, i) =>
    // console.log("item: " + JSON.stringify(item))
    ({
      ...item,
      hash: item.hash, // Date(Number(item.first_seen) * 1000),
      value: ethers.utils.formatUnits(item.value, 'ether'),

      nonce: `${new Number(item.nonce)}`,

      first_seen_date: `${new Date(item.first_seen * 1000)}`,
      age: `${timeSince(new Date(item.first_seen * 1000))} ago`,
      gas_limit: ethers.utils.formatUnits(Number(item.gas), 'wei'),
      gas_price: ethers.utils.formatUnits(Number(item.gasPrice), 'gwei'),
      amount: ethers.utils.formatUnits(item.amount, 'ether'),

      to_etherscan: `https://etherscan.io/address/${item.to}`,
      from_etherscan: `https://etherscan.io/address/${item.from}`,
      max_fee_forpage: ethers.utils.formatUnits(Number(item.maxFeePerGas ? item.maxFeePerGas : 0), 'gwei'),
      max_priority_fee_forpage: ethers.utils.formatUnits(Number(item.maxPriorityFeePerGas ? item.maxPriorityFeePerGas : 0), 'gwei')
    }));


    //console.log("data: " + JSON.stringify(data))
    return (

      <div style={{ height: 800, width: '100%' }}>
        <p className="title"><ReceiptOutlinedIcon fontSize="large" />TXPool.info - Ethereum TX Pool</p>
        <DataGrid
          disableSelectionOnClick
          rows={data}
          columns={columns2}
          pageSize={100}
          // rowsPerPageOptions={[5]}
          // pageSize={pageSize}
          // onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[100]}
          checkboxSelection={false}
          initialState={{
            columns: {
              columnVisibilityModel: {
                // Hide columns status and traderName, the other columns will remain visible
                id: false,
              },
            },
          }}

        />
        {/*         <div style={{ width: '80%' }}>
          <p style={{ align: 'center' }}>Donation: XXXXXX</p>
        </div> */}
        <div style={{ width: '100%', textAlign: 'center' }}>
          <p style={{ textAlign: 'center', fontSize: 'small', width: '100%' }}>
            txpool.info provides real-time ethereum mem/tx pool information and related services.

            <br></br><br></br>
            <Link href="https://txpool.info/">txpool.info  </Link>&copy; 2022
            <br></br>
            <InfoOutlinedIcon /> | <Link href="https://etherscan.io/address/0x2AF17eDf4883d197892d4C95a56f70D85ae9F51D" target="_blank" rel="noreferrer"><VolunteerActivismOutlinedIcon /></Link>
          </p>
          <InfoPage linkText={"about"} />
        </div>

      </div>
    );
  };

  render() {
    /*     return (
      <div>
        { this.myComponent() }
      </div>
    ) */
    return this.buildTable();
  }
}

export default App;

import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';

import TextareaAutosize from '@mui/material/TextareaAutosize';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import ReactGA from 'react-ga';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function TxDetail({ hash, data }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [isShowingAlert, setShowingAlert] = React.useState(false);

  function CopyToClipboardText(textStr) {
    return (
      <CopyToClipboard
      text={textStr}
      onCopy={() => setShowingAlert(true)}
    >
      <Text><span style={{ fontSize: 'small' }}>{textStr}</span></Text>
    </CopyToClipboard>
    )
  }

  function ValueText(textStr){
    return (
      <span style={{ fontSize: 'small' }}>{textStr}</span>
    )
  }

  function DenseTable() {
    ReactGA.pageview(window.location.pathname + window.location.search)
    return (
      <div style={{ height: 450, width: '100%' }}>
        <p className="txdetail-title"> <ReceiptLongOutlinedIcon/> Pending Transaction  <AutorenewOutlinedIcon /> </p>
      <TableContainer component={Paper} sx={{height: '95%'}} style={{}}>
        <Table sx={{ minWidth: 650, height: "max-content" }} size="small" aria-label="transaction detail" style={{fontSize: 'small'}}>
          <TableHead>
            <TableRow
              key={data.hash}>
              <TableCell><strong>Hash</strong></TableCell>
              <TableCell align="right" size='small' style={{fontSize: 'small'}}>

                <CopyToClipboard
                  text={data.hash}
                  onCopy={() => setShowingAlert(true)}
                  style={{fontSize: 'small'}}
                >
                  <Typography color="text.primary"><span style={{ fontSize: 'small' }}>{data.hash}</span></Typography>
                </CopyToClipboard>
              </TableCell>

            </TableRow>
          </TableHead>
          <TableBody>

            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                Time / Age
              </TableCell>
              <TableCell align="right" size='small' style={{fontSize: 'small'}}>
                {' '}
                <Typography color="text.primary"><span style={{ fontSize: 'small' }}>{data.first_seen_date}{' / '} {data.age}</span></Typography>
              </TableCell>
            </TableRow>


            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                Type
              </TableCell>
              <TableCell align="right" size='small' style={{fontSize: 'small'}}>
                {' '}
                {data.type}
              </TableCell>
            </TableRow>

            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                From{' '} <Link href={data.from_etherscan} target="_blank" rel="noreferrer">(etherscan)</Link>
              </TableCell>
              <TableCell align="right" size='small' style={{fontSize: 'small'}}>
                {' '}
                <CopyToClipboard
                  text={data.from}
                  onCopy={() => setShowingAlert(true)}
                >
                <span style={{ fontSize: 'small' }}>{data.from}</span>
                </CopyToClipboard>
    
              </TableCell>
            </TableRow>

            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                To{' '} <Link href={data.to_etherscan} target="_blank" rel="noreferrer">(etherscan)</Link>
              </TableCell>
              <TableCell align="right" style={{fontSize: 'small'}}>
                {' '}

                <CopyToClipboard
                  text={data.to}
                  onCopy={() => setShowingAlert(true)}
                >
                  <span>{data.to}</span>
                </CopyToClipboard>
              </TableCell>
            </TableRow>


            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                Nonce
              </TableCell>
              <TableCell align="right" style={{fontSize: 'small'}}>
                {' '}
                {data.nonce}
              </TableCell>
            </TableRow>

            <TableRow
              key={data.value}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                Value (ether)
              </TableCell>
              <TableCell align="right" style={{fontSize: 'small'}}>
                {' '}
                {data.value}
              </TableCell>
            </TableRow>


            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                Input
              </TableCell>
              <TableCell align="right">

                <CopyToClipboard
                  text={data.input}
                  onCopy={() => setShowingAlert(true)}
                  style={{fontSize: 'small'}}
                >
                  <span>
                    <TextareaAutosize
                      placeholder={data.input}
                      style={{ width: 500 }}
                      disabled
                      defaultValue={data.input}
                    />
                  </span>
                </CopyToClipboard>

              </TableCell>
            </TableRow>

            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                v
              </TableCell>
              <TableCell align="right">
                {' '}
                <CopyToClipboard
                  text={data.v}
                  onCopy={() => setShowingAlert(true)}
                  style={{fontSize: 'small'}}
                >
                  <span>{data.v}</span>
                </CopyToClipboard>
              </TableCell>
            </TableRow>

            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                s
              </TableCell>
              <TableCell align="right">

                <CopyToClipboard
                  text={data.s}
                  onCopy={() => setShowingAlert(true)}
                  style={{fontSize: 'small'}}
                >
                  <span>{data.s}</span>
                </CopyToClipboard>
              </TableCell>
            </TableRow>

            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                r
              </TableCell>
              <TableCell align="right">
                {' '}
                <CopyToClipboard
                  text={data.r}
                  onCopy={() => setShowingAlert(true)}
                  style={{fontSize: 'small'}}
                >
                  <span>{data.r}</span>
                </CopyToClipboard>
              </TableCell>
            </TableRow>
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row" style={{fontWeight: "bold"}}>
                Gas
              </TableCell>
              <TableCell align="right">
                {' '}
              </TableCell>
            </TableRow>

            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                Gas Price (gwei)
              </TableCell>
              <TableCell align="right" style={{fontSize: 'small'}}>
                {' '}
                {data.gas_price}
              </TableCell>
            </TableRow>

            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                Gas Limit (wei)
              </TableCell>
              <TableCell align="right" style={{fontSize: 'small'}}>
                {' '}
                {data.gas_limit}
              </TableCell>
            </TableRow>

            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                Max Fee (gwei)
              </TableCell>
              <TableCell align="right" style={{fontSize: 'small'}}>
                {' '}
                {`${data.max_fee_forpage}`}
              </TableCell>
            </TableRow>

            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                Max Priority Fee (gwei)
              </TableCell>
              <TableCell align="right" size='small' style={{fontSize: 'small'}}>
                {' '}
                {`${data.max_priority_fee_forpage}`}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      </div>
    );
  }
  return (
    <div>
      <Button onClick={handleOpen}>{hash}</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            className={`alert alert-success ${isShowingAlert ? 'alert-shown' : 'alert-hidden'}`}
            onTransitionEnd={() => setShowingAlert(false)}
            align="center"
            color="red"
          >
            <strong><font color="darkred">Copied!</font></strong>
          </div>
          <DenseTable />
        </Box>
      </Modal>
    </div>
  );
}
